<template>
  <el-row>
    <el-row>
      <el-tabs v-model="activeName" @tab-click="handleClick" class="type_tab">
        <el-tab-pane label="路桥项目管理云平台" name="0">
          <el-row class="sub_banner">
            <img src="../assets/images/sub_banner.jpg" alt=""/>
            <div class="title">
              <h3>路桥项目管理云平台</h3>
              <p>
                路桥项目管理云平台主要为项目业主及各层参与单位投资、建设、运营、养护多个工程项目的复杂管理过程而定制，旨在建立一个现代化、务实、高效的信息化管理平台，确保高质量、低成本的完成项目业主及各层参与单位的管理工作。</p>
            </div>
          </el-row>
          <el-row class="gray_bg">
            <div class="content">
              <el-row class="column">
                <p>平台简介<i>PLATFORM INTRODUCTION</i></p>
              </el-row>
              <div class="detail mar_b_40">
                <p>
                  路桥项目管理云平台主要为项目业主及各层参与单位投资、建设、运营、养护多个工程项目的复杂管理过程而定制，旨在建立一个现代化、务实、高效的信息化管理平台，确保高质量、低成本的完成项目业主及各层参与单位的管理工作。</p>
                <p>
                  该平台包括项投资管理、质量管理、安全管理、文档管理、进度管理、办公自动化六大功能模块，并提供了基于BIM的综合分析决策支持应用模块，能完成项目业主及各层参与单位对一个或多个路桥工程项目的建设全过程管理，从而达到项目业主及各层参与单位对各项目投资、建设、运营的有效管理和控制的目标。</p>
              </div>
            </div>
          </el-row>
          <div class="content">
            <el-row class="column">
              <p>解决方案<i>SOLUTION</i></p>
            </el-row>
            <el-row class="mar_b_40">
              <el-col :span="6">
                <div class="road_img">
                  <img src="../assets/images/road1.jpg" alt=""/>
                  <i class="triangle_top"></i>
                </div>
                <div class="road_info">
                  <span>平台提供实景模型的数据上传，并且基于工程项目的信息，添加项目的坐标锚点，可以查看锚点的经度、纬度、以及高程信息，同时可以关联项目的图片、视频、监控等数据。</span>
                </div>
              </el-col>
              <el-col :span="6">
                <div class="road_info">
                  <span>平台通过对模型跟进度文件按照统一的标准设置相同的字段，通过设置模型跟进度文件的挂接算法来实现模型与进度文件的自动挂接，通过对实际进度的收集反馈，来对项目的进度进行4D模拟。</span>
                </div>
                <div class="road_img">
                  <img src="../assets/images/road2.jpg" alt=""/>
                  <i class="triangle_bottom"></i>
                </div>
              </el-col>
              <el-col :span="6">
                <div class="road_img">
                  <img src="../assets/images/road3.jpg" alt=""/>
                  <i class="triangle_top"></i>
                </div>
                <div class="road_info">
                  <span>平台的巡检机制，可有效记录现场质量管理业务细节，所有工作环节规范化。整改工作责任到人，防止发生互相推诿事件。同时项目及公司层领导也可以通过手机实时监控现场的质量管理状况，重大问题随时提醒到手机上，做好事前控制，防患于未然。</span>
                </div>
              </el-col>
              <el-col :span="6">
                <div class="road_info">
                  <span>平台实现多用户协同管理、高效协同沟通的平台。通过管理角色实现多级授权，对于集团化的工程局，可以为总部、各分子公司、项目部分别建立一整套权限管理体系，可通过系统管理员进行可视化分层管理和权限控制，保证整个平台管理及数据的安全性。</span>
                </div>
                <div class="road_img">
                  <img src="../assets/images/road4.jpg" alt=""/>
                  <i class="triangle_bottom"></i>
                </div>
              </el-col>
            </el-row>
          </div>
          <el-row class="gray_bg">
            <div class="content">
              <el-row class="column">
                <p>应用场景<i>APPLICATION SCENARIOS</i></p>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="8" class="scenes">
                  <img class="school" src="../assets/images/scenes0-1.jpg" alt=""/>
                  <h3>公路建设</h3>
                </el-col>
                <el-col :span="8" class="scenes">
                  <img class="school" src="../assets/images/scenes0-2.jpg" alt=""/>
                  <h3>桥梁建设</h3>
                </el-col>
                <el-col :span="8" class="scenes">
                  <img class="school" src="../assets/images/scenes0-3.jpg" alt=""/>
                  <h3>隧道施工</h3>
                </el-col>
              </el-row>
            </div>
          </el-row>
        </el-tab-pane>
        <el-tab-pane label="智慧安全校园" name="1">
          <el-row class="sub_banner">
            <img src="../assets/images/sub_banner.jpg" alt=""/>
            <div class="title">
              <h3>智慧安全校园</h3>
              <p>
                系统平台将围绕“人像可视化、服务管理精细化、数据管理可视化、人员管控高效化”的智慧安全校区设计构建理念，有效集成视频、闸机、报警、人脸布控卡口等系统于一体，形成以校园安保力量为主，人脸布控系统全面监控为辅的可视化管理平台，实现校园管理立体化、可视化和可控化，构建管理、防范、控制于一体的校园治安防控体系。</p>
            </div>
          </el-row>
          <el-row class="gray_bg">
            <div class="content">
              <el-row class="column">
                <p>行业背景<i>BUSINESS BACKGROUND</i></p>
              </el-row>
              <div class="detail mar_b_40">
                <p>
                  公安部、教育部制定了《中小学校、幼儿园安全技术防范系统要求》（GB/T29315-2012），对中小学、幼儿园安全技术防范系统的基本要求、重点部位和区域、系统技术要求等方面的内容予以进一步明确和规范,形成了中小学幼儿园安全技术防控体系建设的基本依据。</p>
                <p>
                  国务院办公厅印发《关于加强中小学幼儿园安全风险防控体系建设的意见》（国办发〔2017〕35号）中指出：着力建设安全校园环境，加强中小学、幼儿园安全工作是全面贯彻党的教育方针，保障学生健康成长、全面发展的前提和基础，关系广大师生的人身安全，事关亿万家庭幸福和社会和谐稳定。</p>
                <p>
                  根据党的十九大报告及《2006-2020年国家信息化发展战略》《国家信息化发展战略纲要》《“十三五”国家信息化规划》《国家中长期教育改革和发展规划纲要（2010-2020年）》《教育信息化十年发展规划（2011-2020年）》《教育信息化“十三五”规划》等有关文件精神，特制定《中小学数字校园建设规范（试行）》（教技〔2018〕5号）。该规范为推动中小学数字校园建设与应用具有指引方向和基础规约的作用。在校园网络安全建设中，配备数字校园智能安防系统，实现校园安全的统一管理和控制。同时深入贯彻落实党的十九大精神，积极推进“互联网+”行动，提升中小学校信息化建设与应用水平，推动信息技术与教育教学的深度融合，切实加快全国教育信息化进程，以教育信息化支撑和引领教育现代化，服务教育强国建设。</p>
              </div>
            </div>
          </el-row>
          <div class="content">
            <el-row class="column">
              <p>解决方案<i>SOLUTION</i></p>
            </el-row>
            <el-row :gutter="20" class="mar_b_40">
              <el-col :span="12">
                <img class="school" src="../assets/images/school.jpg" alt=""/>
              </el-col>
              <el-col :span="12">
                <div class="detail">
                  <p>
                    弘新智能在传统校园监控系统的基础上，提出一套兼具软硬一体化的、功能模块化、数据一体化、高度智能化的功能丰富的智慧校园解决方案，支持灵活配置，从而满足校园不同安防场景的需求。建设以信息场景为主的校园安防可视化平台，实现对监控视频、智能分析、监测报警等信息数据一体化动态掌控，在校园中进行安保巡视、报警联动及应急处置等业务可视化展现，有效构建以科技手段为支撑的校园安防建设体系。</p>
                  <p>实现校园日常工作的自动化、智能化管理，提供涉及人员、设备、建筑信息的主数据管理，维护其一致性。全面监管各个环节，让校园管理及发展更加规范有序、健康稳定。</p>
                  <p>
                    对校园进行全方位监控，并对危险场景进行检测与识别，如烟火、周界入侵、重点区域人员徘徊、攀爬、人员摔倒、打架推搡等，发现异常立即抓拍并触发警报，还可联动现场语音进行提示，方便及时制止和采取救援措施，有效协助校园管理人员的监管工作。</p>
                </div>
              </el-col>
            </el-row>
          </div>
          <el-row class="gray_bg">
            <div class="content">
              <el-row class="column">
                <p>方案架构<i>SOLUTION ARCHITECTURE</i></p>
              </el-row>
              <div class="jiagou mar_b_40">
                <img src="../assets/images/jiagou1.png" alt=""/>
              </div>
            </div>
          </el-row>
          <div class="content">
            <el-row class="column">
              <p>应用场景<i>APPLICATION SCENARIOS</i></p>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="8" class="scenes">
                <img class="school" src="../assets/images/scenes1-1.jpg" alt=""/>
                <h3>高等教育院校</h3>
              </el-col>
              <el-col :span="8" class="scenes">
                <img class="school" src="../assets/images/scenes1-2.jpg" alt=""/>
                <h3>K12院校</h3>
              </el-col>
              <el-col :span="8" class="scenes">
                <img class="school" src="../assets/images/scenes1-3.jpg" alt=""/>
                <h3>幼儿园</h3>
              </el-col>
            </el-row>
          </div>
        </el-tab-pane>
        <el-tab-pane label="智慧明亮厨房" name="2">
          <el-row class="sub_banner">
            <img src="../assets/images/sub_banner.jpg" alt=""/>
            <div class="title">
              <h3>智慧明亮厨房</h3>
              <p>智慧厨房视频监控系统采用安防+微服务、大数据、视音频智能分析处理技术，建设食药监局智慧厨房智能管理平台。平台主要建设在线监控中心、实时视频分析中心、报警中心、信息公示等。</p>
            </div>
          </el-row>
          <el-row class="gray_bg">
            <div class="content">
              <el-row class="column">
                <p>平台简介<i>PLATFORM INTRODUCTION</i></p>
              </el-row>
              <div class="detail mar_b_40">
                <p>智慧厨房视频监控系统采用安防+微服务、大数据、音视频智能分析处理技术，建设食药监局智慧厨房智能管理平台。平台主要建设在线监控中心、实时视频分析中心、报警中心、信息公示等模块，主要功能如下：</p>
                <p>◆ 视频播放：视频监控实时播放、实时视频流推送、历史视频轨迹回放等功能。</p>
                <p>◆ 身份识别：通过人脸识别技术，精确定位违规操作的厨师、对食品安监负有检查任务的安检员的行为精确定位。</p>
                <p>◆ AI视频分析：实现对接入的视频实时分析，对人员违规操作、后厨鼠患、食品安检员在岗情况等行为进行分析。异常情况截图上报云服务器，并发报警信息给单位管理员。</p>
                <p>◆ 违规操作行为分析：未戴口罩、未戴帽子、抽烟、进操作台前未洗手，在规定的时间内是否做了食品安全检查等行为。</p>
                <p>◆ 温湿度监控：实时上报冷库的温度、湿度到云服务器，冷库的温湿度超过系统设定的值，系统自动截图上报服务器，并发报警信息给单位管理员。</p>
                <p>◆ 鼠患报警：对仓储摄像头监控中，如果出现鼠患，则自动截图报警。</p>
              </div>
            </div>
          </el-row>
          <div class="content">
            <el-row class="column">
              <p>产品亮点<i>PRODUCT HIGHLIGHTS</i></p>
            </el-row>
            <el-row :gutter="20" class="kitchen">
              <el-col :span="6" class="scenes">
                <img src="../assets/images/scenes2-4.jpg" alt=""/>
                <h3>着装规范检测</h3>
              </el-col>
              <el-col :span="6" class="scenes">
                <img src="../assets/images/scenes2-5.jpg" alt=""/>
                <h3>人员行为规范</h3>
              </el-col>
              <el-col :span="6" class="scenes">
                <img src="../assets/images/scenes2-6.jpg" alt=""/>
                <h3>鼠患监测</h3>
              </el-col>
              <el-col :span="6" class="scenes">
                <img src="../assets/images/scenes2-7.jpg" alt=""/>
                <h3>安全区烟雾明火</h3>
              </el-col>
            </el-row>
            <el-row :gutter="20" class="kitchen mar_b_40">
              <el-col :span="6" class="scenes">
                <img src="../assets/images/scenes2-8.jpg" alt=""/>
                <h3>抽烟检测</h3>
              </el-col>
              <el-col :span="6" class="scenes">
                <img src="../assets/images/scenes2-9.jpg" alt=""/>
                <h3>口罩监测</h3>
              </el-col>
              <el-col :span="6" class="scenes">
                <img src="../assets/images/scenes2-10.jpg" alt=""/>
                <h3>AI自动跟踪识别</h3>
              </el-col>
              <el-col :span="6" class="scenes">
                <img src="../assets/images/scenes2-11.jpg" alt=""/>
                <h3>自动测温疫情防控预警</h3>
              </el-col>
            </el-row>
          </div>
          <el-row class="gray_bg">
            <div class="content">
              <el-row class="column">
                <p>方案架构<i>SOLUTION ARCHITECTURE</i></p>
              </el-row>
              <div class="jiagou mar_b_40">
                <img src="../assets/images/jiagou2.png" alt=""/>
              </div>
            </div>
          </el-row>
          <div class="content">
            <el-row class="column">
              <p>应用场景<i>APPLICATION SCENARIOS</i></p>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="8" class="scenes">
                <img src="../assets/images/scenes2-1.jpg" alt=""/>
                <h3>学校食堂</h3>
              </el-col>
              <el-col :span="8" class="scenes">
                <img src="../assets/images/scenes2-2.jpg" alt=""/>
                <h3>连锁或单个餐饮企业</h3>
              </el-col>
              <el-col :span="8" class="scenes">
                <img src="../assets/images/scenes2-3.jpg" alt=""/>
                <h3>机关单位食堂</h3>
              </el-col>
            </el-row>
          </div>
        </el-tab-pane>
        <el-tab-pane label="智慧宿管" name="3">
          <el-row class="sub_banner">
            <img src="../assets/images/sub_banner.jpg" alt=""/>
            <div class="title">
              <h3>智慧宿管</h3>
              <p>
                智慧宿管平台，一站式、精细化管理，人脸识别抓取、智能门锁闸机、云考勤、智能水电等助力智慧宿管。支持建立多级宿舍管理组织结构，实现多校区、多宿舍楼栋管理，高效率实现宿舍管理工作，并通过大数据、图表实时展示宿舍整体运行状态。</p>
            </div>
          </el-row>
          <el-row class="gray_bg">
            <div class="content">
              <el-row class="column">
                <p>行业现状<i>INDUSTRY STATUS</i></p>
              </el-row>
              <el-row :gutter="20" type="flex" align="middle">
                <el-col :span="12">
                  <el-row class="sort">
                    <el-col :span="8">
                      <img src="../assets/images/sort_icon1.png" alt=""/>
                      <p>出入人员无法核实</p>
                    </el-col>
                    <el-col :span="8">
                      <img src="../assets/images/sort_icon2.png" alt=""/>
                      <p>宿舍情况人为巡检</p>
                    </el-col>
                    <el-col :span="8">
                      <img src="../assets/images/sort_icon3.png" alt=""/>
                      <p>学生晚归无法监督</p>
                    </el-col>
                  </el-row>
                </el-col>
                <el-col :span="12">
                  <div class="detail">
                    <p>◆ 学校宿舍数据量大，数据统计难</p>
                    <p>◆ 黑名单、陌生人等非法出入行为，人员管控难</p>
                    <p>◆ 归寝统计不精准，查寝难</p>
                    <p>◆ 存在学生晚归未归、夜不归宿现象，学生安全无保障</p>
                  </div>
                </el-col>
              </el-row>
              <el-row :gutter="20" type="flex" align="middle" class="mar_b_40">
                <el-col :span="12">
                  <div class="detail">
                    <p>◆ 人流量出入高峰，易出现人员拥堵</p>
                    <p>◆ 应用系统繁多，缺乏统一管理，信息孤岛难打破</p>
                    <p>◆ 超负荷用电、违规用电等隐患防范不到位，安全事件频发</p>
                    <p>◆ 家长无法获知子女在校情况</p>
                  </div>
                </el-col>
                <el-col :span="12">
                  <el-row class="sort">
                    <el-col :span="8">
                      <img src="../assets/images/sort_icon4.png" alt=""/>
                      <p>宿舍服务人工办理</p>
                    </el-col>
                    <el-col :span="8">
                      <img src="../assets/images/sort_icon5.png" alt=""/>
                      <p>硬件设施无法掌控</p>
                    </el-col>
                    <el-col :span="8">
                      <img src="../assets/images/sort_icon6.png" alt=""/>
                      <p>子女情况无法获知</p>
                    </el-col>
                  </el-row>
                </el-col>
              </el-row>
            </div>
          </el-row>
          <div class="content">
            <el-row class="column">
              <p>产品亮点<i>PRODUCT HIGHLIGHTS</i></p>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="8" class="scenes">
                <img src="../assets/images/scenes3-1.jpg" alt=""/>
                <h3>AI智慧大屏</h3>
              </el-col>
              <el-col :span="8" class="scenes">
                <img src="../assets/images/scenes3-2.jpg" alt=""/>
                <h3>总校与分校管理</h3>
              </el-col>
              <el-col :span="8" class="scenes">
                <img src="../assets/images/scenes3-3.jpg" alt=""/>
                <h3>多端数据同步</h3>
              </el-col>
            </el-row>
            <el-row :gutter="20" class="mar_b_40">
              <el-col :span="8" class="scenes">
                <img src="../assets/images/scenes3-4.jpg" alt=""/>
                <h3>学生安全行为分析</h3>
              </el-col>
              <el-col :span="8" class="scenes">
                <img src="../assets/images/scenes3-5.jpg" alt=""/>
                <h3>高空抛物监测</h3>
              </el-col>
              <el-col :span="8" class="scenes">
                <img src="../assets/images/scenes3-6.jpg" alt=""/>
                <h3>人脸识别考勤</h3>
              </el-col>
            </el-row>
          </div>
          <el-row class="gray_bg">
            <div class="content">
              <el-row class="column">
                <p>方案架构<i>SOLUTION ARCHITECTURE</i></p>
              </el-row>
              <div class="jiagou mar_b_40">
                <img src="../assets/images/jiagou3.png" alt=""/>
              </div>
            </div>
          </el-row>
          <div class="content">
            <el-row class="column">
              <p>应用场景<i>APPLICATION SCENARIOS</i></p>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="8" class="scenes">
                <img src="../assets/images/scenes1-1.jpg" alt=""/>
                <h3>高等教育院校</h3>
              </el-col>
              <el-col :span="8" class="scenes">
                <img src="../assets/images/scenes1-2.jpg" alt=""/>
                <h3>K12院校</h3>
              </el-col>
              <el-col :span="8" class="scenes">
                <img src="../assets/images/scenes1-3.jpg" alt=""/>
                <h3>幼儿园</h3>
              </el-col>
            </el-row>
          </div>
        </el-tab-pane>
        <el-tab-pane label="智慧菜场" name="4">
          <el-row class="sub_banner">
            <img src="../assets/images/sub_banner.jpg" alt=""/>
            <div class="title">
              <h3>智慧菜场</h3>
              <p>以“便民、利民、惠民”为出发点，以保证农产品质量平安、维护群众切身
                利益、方便群众生活、提高文明水平为目标，建设布局合理、服务优质、设施配套、平安可靠的市场服务体系，满足消费者放心购菜和便利购菜的要求。</p>
            </div>
          </el-row>
          <el-row class="gray_bg">
            <div class="content">
              <el-row class="column">
                <p>平台简介<i>PLATFORM INTRODUCTION</i></p>
              </el-row>
              <div class="detail mar_b_40">
                <p>以“便民、利民、惠民”为出发点，以保证农产品质量平安、维护群众切身
                  利益、方便群众生活、提高文明水平为目标，建设布局合理、服务优质、设施配套、平安可靠的市场服务体系，满足消费者放心购菜和便利购菜的要求。</p>
                <p>
                  随着消费者消费需求和生活方式的转变，在经济发达的地区或城市传统菜市场将逐步退出市场舞台。大多数则是向智慧菜场发展。智慧菜场不仅加快了我国农产品流通现代化进程，而且对农产品流通体制改革也起了重要作用。</p>
                <p>
                  在智慧菜场中，拥有一套智慧菜场信息管理系统则是第一步。利用移动互联网和大数据分析技术，以智慧菜场为中心，以智慧菜场信息化为基础，打通智慧菜场上下游信息流、资金流和物流，解决传统农产品流通领域市场信息不公开、政府信息不透明、肉菜追溯难、交易数据采集难和商户信息不能公示等问题。将会更好的更全面的让智慧菜场实现升级与转型。</p>
                <p>利用经营数据督促市场开办方提高食品安全监管水平，落实各项食用农产品管理制度，逐步建立市场内食用农产品的追溯体系，促进企业健康发展，最终赢得市场，让广大人民群众受惠。</p>
              </div>
            </div>
          </el-row>
          <div class="content">
            <el-row class="column">
              <p>产品亮点<i>PRODUCT HIGHLIGHTS</i></p>
            </el-row>
            <el-row :gutter="20" class="mar_b_40">
              <el-col :span="4" class="highlights">
                <img src="../assets/images/highlights_icon1.png" alt=""/>
                <p>食品安全溯源</p>
              </el-col>
              <el-col :span="4" class="highlights">
                <img src="../assets/images/highlights_icon2.png" alt=""/>
                <p>省市区监管</p>
              </el-col>
              <el-col :span="4" class="highlights">
                <img src="../assets/images/highlights_icon3.png" alt=""/>
                <p>AI大数据分析</p>
              </el-col>
              <el-col :span="4" class="highlights">
                <img src="../assets/images/highlights_icon4.png" alt=""/>
                <p>智慧支付 秤具统一</p>
              </el-col>
              <el-col :span="4" class="highlights">
                <img src="../assets/images/highlights_icon5.png" alt=""/>
                <p>全数据采集</p>
              </el-col>
              <el-col :span="4" class="highlights">
                <img src="../assets/images/highlights_icon6.png" alt=""/>
                <p>AI菜品识别</p>
              </el-col>
            </el-row>
          </div>
          <el-row class="gray_bg">
            <div class="content">
              <el-row class="column">
                <p>方案架构<i>SOLUTION ARCHITECTURE</i></p>
              </el-row>
              <div class="jiagou mar_b_40">
                <img src="../assets/images/jiagou4.png" alt=""/>
              </div>
            </div>
          </el-row>
          <div class="content">
            <el-row class="column">
              <p>应用场景<i>APPLICATION SCENARIOS</i></p>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="8" class="scenes">
                <img src="../assets/images/scenes4-1.jpg" alt=""/>
                <h3>大型菜场</h3>
              </el-col>
              <el-col :span="8" class="scenes">
                <img src="../assets/images/scenes4-2.jpg" alt=""/>
                <h3>连锁超市</h3>
              </el-col>
              <el-col :span="8" class="scenes">
                <img src="../assets/images/scenes4-3.jpg" alt=""/>
                <h3>批发市场</h3>
              </el-col>
            </el-row>
          </div>
        </el-tab-pane>
        <el-tab-pane label="智慧司法" name="5">
          <el-row class="sub_banner">
            <img src="../assets/images/sub_banner.jpg" alt=""/>
            <div class="title">
              <h3>智慧司法</h3>
              <p>突出信息技术引领，以智慧建设入手，用
                “AI+互联网+公共法律服务”思维实现“民呼我应、企呼我应”，畅通公共法律服务最后一公里，打造全面覆盖司法行政机关、社会群众、企业、律师四个方向，构筑“线上、线下+公共法律服务平台”全域体系化、资源化、高价值的司法行政服务体系，建设“大整合、高共享、全规范”的智慧司法管理平台。</p>
            </div>
          </el-row>
          <el-row class="gray_bg">
            <div class="content">
              <el-row class="column">
                <p>行业背景<i>BUSINESS BACKGROUND</i></p>
              </el-row>
              <div class="detail mar_b_40">
                <p>
                  司法部《全面深化司法行政改革纲要》明确指出了重新组建后的司法部的新职能、新定位，特别是贯穿了司法部“一个统筹、四大职能”的工作布局，“一个统筹”统筹全面依法治国工作，在全面履职的基础之上，要充分发挥好行政立法、行政执法、刑事执行、公共法律服务的四大职能。</p>
                <p>
                  《2021年司法行政改革工作要点》提出了要着眼于贯彻新发展理念、构建新发展格局，重点聚焦法治建设领域人民群众反映强烈的突出问题、制约司法行政高质量发展的体制机制障碍，统筹推进行政执法体制机制改革、加快公共法律服务体系建设，及时完善协同配套措施，发挥整体效应；又强化以重点突破引领改革纵深推进，精准研究提出推进行政复议体制改革、完善监狱管理体制、健全完善律师制度、加强仲裁制度改革等方面的重大改革举措，为司法行政高质量发展提供强大动力。</p>
              </div>
            </div>
          </el-row>
          <div class="content">
            <el-row class="column">
              <p>解决方案<i>SOLUTION</i></p>
            </el-row>
            <div class="jiagou mar_b_40">
              <img src="../assets/images/jiagou5-1.png" alt=""/>
            </div>
          </div>
          <el-row class="gray_bg">
            <div class="content">
              <el-row class="column">
                <p>方案架构<i>SOLUTION ARCHITECTURE</i></p>
              </el-row>
              <div class="jiagou mar_b_40">
                <img src="../assets/images/jiagou5-2.png" alt=""/>
              </div>
            </div>
          </el-row>
          <div class="content">
            <el-row class="column">
              <p>应用场景<i>APPLICATION SCENARIOS</i></p>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="8" class="scenes">
                <img src="../assets/images/scenes5-1.jpg" alt=""/>
                <h3>法律咨询</h3>
              </el-col>
              <el-col :span="8" class="scenes">
                <img src="../assets/images/scenes5-2.jpg" alt=""/>
                <h3>矛盾调解</h3>
              </el-col>
              <el-col :span="8" class="scenes">
                <img src="../assets/images/scenes5-3.jpg" alt=""/>
                <h3>司法服务大厅</h3>
              </el-col>
            </el-row>
          </div>
        </el-tab-pane>
      </el-tabs>
    </el-row>
  </el-row>
</template>

<script>
export default {
  name: "Solution",
  data() {
    return {
      activeName: '0'
    };
  },
  created() {
    this.activeName = this.$route.params.id
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    }
  }
}
</script>

<style scoped>
.school {
  width: 100%;
  height: 340px;
  object-fit: cover;
}

.jiagou {
  text-align: center;
}

.jiagou img {
  width: 1100px;
}

.scenes img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.kitchen .scenes img {
  width: 100%;
  height: 160px;
  object-fit: cover;
}

.scenes h3 {
  margin: 15px 0;
  text-align: center;
  font-size: 22px;
  font-weight: normal;
}

.sort .el-col-8 {
  text-align: center;
  line-height: 1.5;
}

.sort .el-col-8 img {
  width: 48px;
  height: 48px;
  padding: 17px;
  border: 2px dotted #a4bbfd;
  border-radius: 50%;
}

.sort .el-col-8 p {
  margin: 15px 0 0 0;
  font-size: 16px;
}

.road_img {
  height: 260px;
  position: relative;
  display: flex;
  justify-content: center;
  overflow: hidden;
}

.road_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 0.6s;
}

.road_img img:hover {
  transform: scale(1.2);
}

.road_img i {
  position: absolute;
  display: block;
  width: 10px;
  height: 10px;
  background-color: #fff;
  transform: rotate(45deg);
}

.triangle_top {
  bottom: -5px;
}

.triangle_bottom {
  top: -5px;
}

.road_info {
  background-color: #f5f8ff;
  height: 260px;
  box-sizing: border-box;
  padding: 20px;
}

.road_info span {
  color: #858ab4;
  font-size: 15px;
  line-height: 2;
}
</style>
